
  @import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Work+Sans:wght@300&display=swap');
.App{
    min-height: 100vh;
    display: flex;
    background-image: url("./background.png");
    background-size: cover;
    background-position: center ;
}
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.281);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}